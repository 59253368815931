<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <v-tabs-items v-model="tab" class="card" touchless>
                    <v-tab-item value="tab-1">

                        <div class="row">
                            <div class="col-lg-12">
                        
                                <div class="card elevation-5">
                                    <div class="card-body">
                                        <v-spacer></v-spacer>
                                        <div class="row" style="margin: 5px">

                                            <div class="col-lg-6">
                                                <v-autocomplete
                                                    v-model="customerModel"
                                                    :loading="loadingCust"
                                                    :items="customerLists"
                                                    item-value="CustomerId"
                                                    item-text="NamaCustomer"
                                                    :search-input.sync="search"
                                                    cache-items
                                                    flat
                                                    hide-no-data
                                                    hide-details
                                                    allow-overflow
                                                    dense
                                                    label="Search Customer" prepend-icon="mdi-account-multiple"
                                                    clearable
                                                    @change="(event) => onChangeCustomer(event)"
                                                ></v-autocomplete>
                                            </div>
                                            <div class="col-lg-3">
                                                <v-select v-model="custTypeModel" :items="custTypeLists" default="" item-value="str1" item-text="str2" label="Cust. Type" clearable dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                                            </div>
                                            <div class="col-lg-3">
                                                <span class="text-info">Customer credit status</span>
                                                <ul class="list-group list-group-horizontal" style="font-size: 10px;">
                                                    <li class="list-group-item">LIMIT : {{ creditLimitModel ? creditLimitModel : '0' }}</li>
                                                    <li class="list-group-item">USAGE : {{ creditUsageModel ? creditUsageModel : '0' }}</li>
                                                    <li class="list-group-item">REMAIN : {{ creditRemainModel ? creditRemainModel : '0' }}</li>
                                                </ul>
                                            </div>
                                            <div class="col-lg-12">

                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-responsive">
                                                            <tr>
                                                                <td class="text-center" scope="col" rowspan="2">Not yet due</td>
                                                                <td class="text-center" scope="col" colspan="4">Over due</td>
                                                                <td class="text-center" scope="col" rowspan="2">Total</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center">1-30</td>
                                                                <td class="text-center">31-60</td>
                                                                <td class="text-center">60-90</td>
                                                                <td class="text-center">>90</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center">{{ notYetDueModel ? $store.getters.convertToCurrency(notYetDueModel) : 0 }}</td>
                                                                <td class="text-center">{{ overDue1Model ? $store.getters.convertToCurrency(overDue1Model) : 0 }}</td>
                                                                <td class="text-center">{{ overDue2Model ? $store.getters.convertToCurrency(overDue2Model) : 0 }}</td>
                                                                <td class="text-center">{{ overDue3Model ? $store.getters.convertToCurrency(overDue3Model) : 0 }}</td>
                                                                <td class="text-center">{{ overDue4Model ? $store.getters.convertToCurrency(overDue4Model) : 0 }}</td>
                                                                <td class="text-center">{{ totalDueModel ? $store.getters.convertToCurrency(totalDueModel) : 0 }}</td>
                                                            </tr>
                                                    </table>
                                                </div>

                                            </div>
                                            <div class="col-lg-2">
                                                <v-text-field class="text-black" v-model="custIDModel" label="Cust. ID" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                                            </div>
                                            <div class="col-lg-6">
                                                <v-text-field v-model="custNameModel" label="Name" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                                            </div>
                                            <div class="col-lg-3">
                                                <v-select v-model="niknpwpModel" :items="niknpwpLists" item-value="nik_npwp" :item-text="item => item.nik_npwp +' - '+ item.CustomerName" label="NIK / NPWP" dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <div class="col-lg-1 text-right">
                                                <v-btn class="" color="primary" elevation="2" small dense rounded @click="custDetail()" dark>Info</v-btn>
                                            </div>
                                            <!-- <div class="col-lg-9">
                                                <v-text-field v-model="custAddressModel" label="Address" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                                            </div> -->
                                            <div class="col-lg-2">
                                                <v-text-field v-model="downPaymentModel" label="Down Payment (DP)" @keypress="isNumber($event)" dense prepend-icon="mdi-wallet"></v-text-field>
                                            </div>
                                            <!-- <div class="col-lg-3">
                                                <v-text-field v-model="custCityModel" label="City" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                                            </div>
                                            <div class="col-lg-3">
                                                <v-text-field v-model="custProvinceModel" label="Province" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                                            </div> -->
                                            <div class="col-lg-2 col-6">
                                                <v-select v-model="paytermModel" :items="paytermLists" item-value="pay_term" item-text="pay_term" label="PAY. TERM" dense prepend-icon="mdi-wallet"></v-select>
                                            </div>
                                            <div class="col-lg-2 col-6">
                                                <v-select v-model="shiptermModel" :items="shiptermLists" item-value="value" item-text="text" label="SHIP. TERM" dense prepend-icon="mdi-truck" @change="(event) => onChangeShipTerm(event)"></v-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-select v-model="showSKModel" :items="showSKLists" default="" item-value="value" item-text="text" label="Show Agreement (S/K)" dense prepend-icon="mdi-newspaper"></v-select>
                                            </div>
                                            <div class="col-lg-4">
                                                <!-- <v-select v-model="brokerModel" :items="brokerLists" default="" item-value="broker_id" item-text="namabroker" label="Broker" dense prepend-icon="mdi-account"></v-select> -->
                                                <v-autocomplete
                                                    v-model="brokerModel"
                                                    :items="brokerLists"
                                                    item-value="broker_id"
                                                    item-text="namabroker"
                                                    cache-items
                                                    flat
                                                    hide-no-data
                                                    hide-details
                                                    allow-overflow
                                                    dense
                                                    label="Broker" prepend-icon="mdi-account"
                                                ></v-autocomplete>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h6 class="card-title">Shipping Address</h6>
                                                        <div class="row">
                                                            <div class="col-lg-4 col-12">
                                                                <v-text-field v-model="xpdcNameModel" label="Expedition Name" v-show="xpdcDetail == true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                                                            </div> 
                                                            <div class="col-lg-4 col-12">
                                                                <v-text-field v-model="xpdcAddressModel" label="Expedition Address" v-show="xpdcDetail == true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <v-text-field v-model="xpdcPhoneModel" label="Expedition Phone" v-show="xpdcDetail == true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                                                            </div>
                                                            <div class="col-lg-6 col-12">
                                                                <v-dialog ref="dialog" v-model="dateShipModal" :return-value.sync="dateShipModel" persistent width="290px" >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field v-model="dateShipModel" label="Request Ship. Date"  readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                                                    </template>
                                                                    <v-date-picker v-model="dateShipModel" :show-current="true" :min=minDate :max=maxMonth @click:date="$refs.dialog.save(dateShipModel)" scrollable>
                                                                        <v-spacer></v-spacer>
                                                                        <v-btn text color="primary" @click="dateShipModal = false">Cancel</v-btn>
                                                                    </v-date-picker>
                                                                </v-dialog>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <!-- <v-text-field v-model="shipAddressModel" label="SHIPPING ADDRESS (Leave it empty, will use original customer address)" dense prepend-icon="mdi-google-maps"></v-text-field> -->
                                                                <v-radio-group class="mt-0" v-model="addressType" dense row @change="onChangeAddressType()" :disabled="addressTypeDisable">
                                                                    <v-radio label="Default Address" value="1"></v-radio>
                                                                    <v-radio label="Consignee Address" value="2"></v-radio>
                                                                    <v-radio label="Custom Address" value="3"></v-radio>
                                                                </v-radio-group>
                                                            </div>
                                                            <div class="col-lg-12 col-12" v-show="consigneeShow">
                                                                <v-select v-model="consigneeModel" :items="consigneeLists" item-value="cons_id" item-text="cons_name" label="Select Consignee" @change="(event) => onChangeConsignee(event)" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                                            </div>
                                                            <div class="col-lg-3 col-12" v-show="consigneeShow">
                                                                <v-text-field v-model="consigneeReceiverModel" label="Receiver" disabled dense prepend-icon="mdi-google-nearby"></v-text-field>
                                                            </div>
                                                            <div class="col-lg-5 col-12" v-show="consigneeShow">
                                                                <v-text-field v-model="consigneeAddressModel" label="Address" disabled dense prepend-icon="mdi-google-nearby"></v-text-field>
                                                            </div>
                                                            <div class="col-lg-2 col-12" v-show="consigneeShow">
                                                                <v-text-field v-model="consigneeCityModel" label="City" disabled dense prepend-icon="mdi-google-nearby"></v-text-field>
                                                            </div>
                                                            <div class="col-lg-2 col-12" v-show="consigneeShow">
                                                                <v-text-field v-model="consigneeProvinceModel" label="Province" disabled dense prepend-icon="mdi-google-nearby"></v-text-field>
                                                            </div>
                                                            <div class="col-lg-3 col-12" v-show="customAddressShow">
                                                                <v-text-field v-model="customReceiverModel" label="Receiver" dense :disabled="disableCustAddress" prepend-icon="mdi-google-nearby"></v-text-field>
                                                            </div>
                                                            <div class="col-lg-5 col-12" v-show="customAddressShow">
                                                                <v-text-field v-model="customAddressModel" label="Address" dense :disabled="disableCustAddress" prepend-icon="mdi-google-nearby"></v-text-field>
                                                            </div>
                                                            <div class="col-lg-2 col-12" v-show="customAddressShow">
                                                                <v-text-field v-model="customCityModel" label="City" dense :disabled="disableCustAddress" prepend-icon="mdi-google-nearby"></v-text-field>
                                                            </div>
                                                            <div class="col-lg-2 col-12" v-show="customAddressShow">
                                                                <v-text-field v-model="customProvinceModel" label="Province" dense :disabled="disableCustAddress" prepend-icon="mdi-google-nearby"></v-text-field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <v-textarea v-model="remarkModel" label="REMARK" rows="2" clearable dense outlined></v-textarea>
                                            </div>
                                            <div class="col-lg-12">
                                                <span class="text-primary">Photo of Cust. Order : </span><input type="file" name="image" id="image" @change="fileChange" class="" accept="image/*" />
                                            </div>
                                            <div class="col-lg-4">
                                                <v-btn class="mr-4" color="success" elevation="2" block large rounded @click="addSpecialInstructions()">Add Special instructions<v-icon right dark>mdi-note-multiple-outline</v-icon></v-btn>
                                            </div>
                                            <div class="col-lg-4">
                                                <v-btn class="mr-4" color="deep-orange darken-4" elevation="2" block large rounded @click="addOverdueRemark()" v-show="visibleOverdueRemark" dark>Overdue Remark<v-icon right dark>mdi-note-multiple-outline</v-icon></v-btn>
                                            </div>
                                            <div class="col-lg-4">
                                                <v-btn class="mr-4" color="primary" elevation="2" block large rounded @click="addItems()">Proceed to Add Items<v-icon right dark>mdi-cart</v-icon></v-btn>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-tab-item>
                    <v-tab-item value="tab-2" >

                        <div class="row">
                            <div class="col-lg-12">
                        
                                <div class="card elevation-5">
                                    <div class="card-body">
                                        <span class="text-info">Cust. ID : {{ custIDModel ? custIDModel : 'nn' }}  <br />  Cust. Name : {{ custNameModel ? custNameModel : 'nn' }} <br />  Cust. Address : {{ custAddressModel ? custAddressModel : 'nn' }}</span>
                                        <v-spacer></v-spacer>
                                        <hr class="line1" />
                                        <div class="row ma-1">
                                            <div class="col-lg-2">
                                                <v-select v-model="brandModel" :items="brandLists" item-value="value" item-text="text" label="BRAND" @change="brandOnChange()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-select v-model="categoryGroupModel" :items="categoryGroupLists" default="" item-value="cat_group" item-text="cat_group" label="CATEGORY GROUP" @change="(event) => categoryGroupOnChange(event)" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-select v-model="categoryModel" :items="categoryLists" item-value="category_id" item-text="cat_desc" :label="categoryLabel" @change="categoryOnChange()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-select v-model="machTypeModel" :items="machTypeLists" item-value="mach_type" :item-text="item => item.mach_type +' - '+ item.descr" :label="machTypeLabel" @change="machTypeOnChange()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-select v-model="thicknessModel" :items="thicknessLists" item-value="Tebal" item-text="Tebal" :label="thicknessLabel" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-select v-model="widthModel" :items="widthLists" item-value="matrl_width" item-text="matrl_width" :label="widthLabel" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-select v-model="colorModel" :items="colorLists" item-value="KodeWarna" item-text="NamaWarna" :label="colorLabel" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-select v-model="materialModel" :items="materialLists" item-value="Jenis" item-text="Jenis" :label="materialLabel" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <hr class="line1" />
                                            <div class="col-lg-4">
                                                <v-select v-model="prodIDModel" :items="prodIDLists" item-value="BarangJadiId" :item-text="item => item.BarangJadiId +' - '+ item.NamaBarang" :label="prodCodeText" :loading="loadingProdCode" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-text-field v-model="lengthModel" label="LENGTH" @keypress="isNumber($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-select v-model="addIDModel" :items="addIDLists" item-value="add_id" item-text="descr" label="ADD ID" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-select v-model="torIDModel" :items="torIDLists" item-value="tor_id" item-text="descr" label="TOR ID" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <v-text-field v-model="quantityModel" label="QUANTITY" @keypress="isNumber($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                                            </div>
                                            <div class="col-lg-3">
                                                <v-text-field v-model="priceModel" label="PRICE" @keypress="isNumber($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                                            </div>
                                            <div class="col-lg-3">
                                                <v-radio-group v-model="priceModeModel" dense row>
                                                    <v-radio label="price By Length" value="M"></v-radio>
                                                    <v-radio label="price By Quantity" value="U"></v-radio>
                                                </v-radio-group>
                                            </div>
                                            <div class="col-lg-3">
                                                <v-text-field v-model="brokerFeeModel" label="FEE LUAR" @keypress="isNumber($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                                            </div>
                                            <hr class="line1" />
                                            <div class="col-lg-12">
                                                <v-textarea v-model="remarkItemModel" label="REMARK" rows="2" clearable dense prepend-icon="mdi-note-outline"></v-textarea>
                                            </div>
                                            <div class="col-lg-6 text-left">
                                                <v-btn class="mr-4" color="green" elevation="2" large rounded @click="newOrder()" dark><v-icon dark>mdi-new-box</v-icon> New Order</v-btn>
                                            </div>
                                            <div class="col-lg-6 text-right">
                                                <v-btn class="mr-4" color="primary" elevation="2" large rounded @click="storeData()">Add Items to Cart<v-icon dark>mdi-cart-plus</v-icon></v-btn>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-12">

                                <div class="col-lg-12 cardResult">
                                    <div class="card elevation-5">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-6 col-lg-6 text-left">
                                                    <h5 class="card-title ma-3 text-primary">Cart List <v-icon class="text-primary">mdi-cart-plus</v-icon></h5>
                                                </div>
                                                <div class="col-6 col-lg-6 text-right">
                                                    <h5 class="card-title ma-3 text-primary">Total Weight : {{ totalWgtOrder ? totalWgtOrder : 0 }} Kg</h5>
                                                </div>
                                            </div>
                                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :loading="loadingDatatable" dense>
                                                <template v-slot:[`item.no`]="{ index }">
                                                    {{ index + 1 }}
                                                </template>
                                                <template v-slot:[`item.total_price`]="{ item }">
                                                    {{ item.total_price }}
                                                </template>
                                                <template v-slot:[`item.delete`]="{ item }">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn class="ma-1" color="red" small elevation="2" @click="deleteItem(item)" v-on="on" dark><v-icon dark>mdi-delete</v-icon></v-btn>
                                                        </template>
                                                        <span>delete</span>
                                                    </v-tooltip>
                                                </template>
                                                <template v-slot:footer>
                                                    <hr />
                                                    <div class="text-right mr-6"><span class="font-weight-bold">Total : {{ subTotal ? subTotal : 0 }}</span></div>
                                                    <hr />
                                                    <div class="text-right mr-6">
                                                        <v-btn class="ma-2" color="primary" elevation="2" medium rounded @click="confirmOrder()">CONFIRM ORDER</v-btn>
                                                    </div>
                                                </template>
                                            </v-data-table>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </v-tab-item>
                </v-tabs-items>
            </div>

            <div class="modal fade modal-xl" id="specialInstructionsModal" tabindex="-1" aria-labelledby="specialInstructionsLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Add Special Instructions</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="col-lg-12">
                                        <v-text-field v-model="specialInstructions1Model" label="Special Instructions 1" ></v-text-field>
                                    </div>
                                    <div class="col-lg-12">
                                        <v-text-field v-model="specialInstructions2Model" label="Special Instructions 2" ></v-text-field>
                                    </div>
                                    <div class="col-lg-12">
                                        <v-text-field v-model="specialInstructions3Model" label="Special Instructions 3" ></v-text-field>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="col-lg-12">
                                        <v-text-field v-model="shipInstructions1Model" label="Ship Instructions 1 (Muncul di surat jalan)"></v-text-field>
                                    </div>
                                    <div class="col-lg-12">
                                        <v-text-field v-model="shipInstructions2Model" label="Ship Instructions 2"></v-text-field>
                                    </div>
                                    <div class="col-lg-12">
                                        <v-text-field v-model="shipInstructions3Model" label="Ship Instructions 3"></v-text-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>

            <div class="modal fade modal-xl" id="overdueRemarkModal" tabindex="-1" aria-labelledby="overdueRemarkLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Overdue Remark</h5>
                        </div>
                        <div class="modal-body">
                            <v-textarea v-model="overdueRemarkModel" label="REMARK" rows="2" clearable dense prepend-icon="mdi-note-outline"></v-textarea>
                        </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>

            <v-dialog
                v-model="dialog_delete"
                max-width="80%"
                persistent
            >
                <v-card style="overflow: hidden;">
                    <v-card-title class="p-1">                        
                        <v-spacer></v-spacer>
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="#e83e8c;"
                            @click="[dialog_delete = false, this.order_item = null]"
                        >
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <h6 class="text-center">
                                        {{ order_item ? order_item.book_id : '' }}
                                    </h6>
                                    <p class="text-center">
                                        Are you sure Delete this Order Item ？ <br>
                                        If yes, please to fill form this below
                                    </p>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <h6 class="blue-lcd mb-1">Reason <span class="red--text"><strong>* </strong></span></h6>   
                                    <v-autocomplete
                                        dense
                                        solo
                                        clearable
                                        v-model="reason_id"
                                        :items="reasons"
                                        item-value="str1"
                                        item-text="str2"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>  
                                </v-col>
                                <v-col cols="12">
                                    <h6 class="blue-lcd mb-1">Remark</h6>  
                                    <v-textarea solo v-model="remark" rows="3" clearable></v-textarea> 
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="text-center p-3">
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-btn block class="rounded-l p-3" @click="[dialog_delete = false, this.order_item = null]">Cancel</v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3"> 
                                <v-btn block class="rounded-l text-white p-3" color="#003871" @click="deleteItem()">Delete</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogCustDetail" max-width="80%">
                <div class="card elevation-5">
                    <div class="card-body mt-3">
                        <div class="row">
                            <div class="col-lg-4">
                                <v-text-field v-model="lastSalesModel" label="Last Sales" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-4">
                                <v-text-field v-model="custNameModel" label="Name" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-4">
                                <v-text-field v-model="custAddressModel" label="Address" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-6">
                                <v-text-field v-model="custCityModel" label="City" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-6">
                                <v-text-field v-model="custProvinceModel" label="Province" :readonly="true" dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-12 text-right">
                                <v-btn class="" color="red" elevation="2" small dense rounded @click="dialogCustDetail = !dialogCustDetail" dark>close</v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </v-dialog>

        </div>

    </div>
    
</template>

<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Customer Order',
                disabled: false,
                href: '#',
                },
                {
                text: 'Create Order',
                disabled: true,
                href: '#',
                },
            ],
            customerModel: '',
            customerLists: [],
            niknpwpModel: '',
            niknpwpLists: [],
            custIDModel: '',
            custIdModel: '',
            custNameModel: '',
            custAddressModel: '',
            custCityModel: '',
            custProvinceModel: '',
            creditLimitModel: '',
            creditUsageModel: '',
            creditRemainModel: '',
            paytermModel: '',
            paytermLists: [],
            shiptermModel: '',
            shiptermLists: [
                {
                    value: 'FRANCO CUSTOMER',
                    text: 'FRANCO CUSTOMER'
                },
                {
                    value: 'FRANCO EXPEDISI',
                    text: 'FRANCO EXPEDISI'
                },
                {
                    value: 'LOCO',
                    text: 'LOCO'
                }
            ],
            shipAddressModel: '',
            dateShipModel: '',
            dateShipModal: false,
            dateShipFormatted: '',
            showSKModel: '',
            showSKLists: [
                {
                    value: 'Y',
                    text: 'YES'
                },
                {
                    value: 'N',
                    text: 'NO'
                }
            ],
            remarkModel: '',
            tab: 'tab-1',
            specialInstructions1Model: '',
            specialInstructions2Model: '',
            specialInstructions3Model: '',
            shipInstructions1Model: '',
            shipInstructions2Model: '',
            shipInstructions3Model: '',
            brandModel: '',
            brandLists:[
                {
                    value: 'KENCANA',
                    text: 'KENCANA'
                },
                {
                    value: 'ARYA',
                    text: 'ARYA'
                },
                {
                    value: 'SEMANGGI',
                    text: 'SEMANGGI'
                },
            ],
            categoryModel: '',
            categoryLists: [],
            machTypeModel: '',
            machTypeLists: [],
            thicknessModel: '',
            thicknessLists: [],
            widthModel: '',
            widthLists: [],
            lengthModel: '',
            colorModel: '',
            colorLists: [],
            materialModel: '',
            materialLists: [],
            addIDModel: '',
            addIDLists: [],
            torIDModel: '',
            torIDLists: [],
            quantityModel: '',
            priceModel: '',
            priceModeModel: 'M',
            prodIDModel: '',
            prodIDLists: [],
            remarkItemModel: '',
            headers: [
                { text: 'NO.', value: 'no', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'BRAND', value: 'brand_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MACH. TYPE', value: 'mach_type', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROD. CODE', value: 'barangjadiid', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'THICKNESS', value: 'tebal', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'panjang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'QTY.', value: 'lembar', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRICE', value: 'set_price', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL', value: 'total_price', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'remark', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'delete', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            itemLists: [],
            loadingDatatable: false,
            prodCodeText: 'PRODUCT CODE',
            loadingProdCode: false,
            book_idModel: '',
            imageModel : '',
            subTotal : '',
            total : '',
            tax : '',
            totalTerbilang: '',
            loadingCust: false,
            search: null,
            userGroup: null,
            appl_id: 'SALES ORDER',
            xpdcNameModel: '',
            xpdcAddressModel: '',
            xpdcPhoneModel: '',
            xpdcDetail: false,
            categoryGroupModel: '',
            categoryGroupLists: [],
            categoryLabel: 'CATEGORY',
            machTypeLabel: 'MACH TYPE',
            thicknessLabel: 'THICKNESS',
            widthLabel: 'WIDTH',
            colorLabel: 'COLOR',
            materialLabel: 'MATERIAL',
            notYetDueModel : 0,
            overDue1Model: 0,
            overDue2Model: 0,
            overDue3Model: 0,
            overDue4Model: 0,
            totalDueModel: 0,
            visibleOverdueRemark: false,
            overdueRemarkModel: '',
            downPaymentModel: 0,
            totalWgtOrder: 0,
            addressType: '1',
            addressTypeDisable: false,
            consigneeModel: '',
            consigneeLists: [],
            consigneeShow: false,
            customReceiverModel: '',
            customAddressModel: '',
            customCityModel: '',
            customProvinceModel: '',
            customAddressShow: true,
            consigneeReceiverModel: '',
            consigneeAddressModel: '',
            consigneeCityModel: '',
            consigneeProvinceModel: '',
            fixedReceiver: '',
            fixedAddress: '',
            fixedCity: '',
            fixedProvince: '',
            reason_id: '',
            reasons: [],
            remark: '',
            dialog_delete: false,
            order_item: null,
            custTypeModel: '',
            custTypeLists: [],
            dialogCustDetail: false,
            disableCustAddress: false,
            minDate: '',
            // maxMonth: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            maxMonth: '',
            brokerModel: '',
            brokerLists: [],
            brokerFeeModel: '',
            lastSalesModel: '',

        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        
        await this.initialize()
        await this.getApplConstant()

        this.$store.dispatch('setOverlay', false)

    },
    methods:{
        
        async initialize(){

            // this.minDate = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            // this.maxMonth = this.currentDate2()

            this.minDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.maxMonth = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000 * 100)).toISOString().substr(0, 10)

            this.$store.dispatch('setOverlay', true)
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.paytermLists = res.data.payterm
                this.categoryGroupLists = res.data.category
                this.categoryLists = res.data.category
                this.machTypeLists = res.data.machType
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.colorLists = res.data.color
                this.materialLists = res.data.material
                this.addIDLists = res.data.add_id
                this.torIDLists = res.data.tor_id
                this.custTypeLists = res.data.custType
                this.brokerLists = res.data.broker
                
                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })
        },

        async getApplConstant(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/appl_constan?key_id=RCANCEL`, '', false, false, false)

            if (respData.status === 200) {
                this.reasons = respData.data
            }
        },

        async getData(id){

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/getData`, { 
                    book_id : id,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.itemLists = res.data.result
                this.tax = res.data.tax
                this.subTotal = res.data.subTotal
                this.total = res.data.total
                this.totalTerbilang = res.data.totalTerbilang
                this.totalWgtOrder = res.data.wgtOrder[0].wgt

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        updateCustomerDropdown(id)
        {
            // var id = $('#customer').val()
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/getCustomer?id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                .then(res => {
                        if(res.data.result.length != 0){
                            this.customerLists = res.data.result
                        } else {
                            Swal.fire({
                                text: 'Customer not found, please try again !',
                                icon: 'warning',
                            })
                        }
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401') {
                        this.$router.push('/');
                    } else {
                        Swal.fire({
                            text: err.response.data.data,
                            icon: 'error',
                        })
                    }

                })
            } else {
                this.customerLists = []
            }
        },

        emptyCustomer(id)
        {
            if(id == null || id == ''){
                this.niknpwpModel = ''
                this.niknpwpLists = []
                this.customerLists = []
                this.custIDModel = ''
                this.custNameModel = ''
                this.custAddressModel = ''
                this.custCityModel = ''
                this.custProvinceModel = ''
            }
        },

        async getCustomer(id){

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/getCustomer`, { 
                    id : id,
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.customerLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async onChangeCustomer(id)
        {
            if(id == null || id == '' ){

                this.niknpwpModel = ''
                this.niknpwpLists = []
                this.customerLists = []
                this.custIDModel = ''
                this.custNameModel = ''
                this.custAddressModel = ''
                this.custCityModel = ''
                this.custProvinceModel = ''
                this.paytermLists = []
                this.remarkModel = ''
                this.dateShipModel = ''
                this.shipAddressModel = ''
                this.creditLimitModel = 0
                this.creditUsageModel = 0
                this.creditRemainModel = 0
                this.specialInstructions1Model = ''
                this.specialInstructions2Model = ''
                this.specialInstructions3Model = ''
                this.shipInstructions1Model = ''
                this.shipInstructions2Model = ''
                this.shipInstructions3Model = ''
                this.notYetDueModel = ''
                this.overDue1Model = ''
                this.overDue2Model = ''
                this.overDue3Model = ''
                this.overDue4Model = ''
                this.totalDueModel = ''
                this.visibleOverdueRemark = false

            } else {

                this.$store.dispatch('setOverlay', true)
                this.niknpwpModel = ''
                this.niknpwpLists = []
                this.notYetDueModel = ''
                this.overDue1Model = ''
                this.overDue2Model = ''
                this.overDue3Model = ''
                this.overDue4Model = ''
                this.totalDueModel = ''
                this.visibleOverdueRemark = false

                await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/getCustomerDetails?id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    if(res.data.niknpwp.length == 0){
                        Swal.fire({
                            text: 'This customer hasnt registered an NPWP/NIK yet, please register first before create order !',
                            icon: 'warning',
                        })

                        this.$store.dispatch('setOverlay', false)

                        return
                    }

                    if(res.data.payterm){
                        this.paytermLists = []
                        this.paytermLists = res.data.payterm
                        this.paytermModel = res.data.payterm[0].pay_term
                    }

                    this.custIDModel = res.data.result.CustomerId
                    this.custNameModel = res.data.result.NamaCustomer
                    this.custAddressModel = res.data.result.Alamat
                    this.custCityModel = res.data.result.Kota
                    this.custProvinceModel = res.data.result.Propinsi
                    this.niknpwpModel = res.data.niknpwp[0].nik_npwp
                    this.niknpwpLists = res.data.niknpwp
                    this.creditLimitModel = res.data.credit_limit[0].credit_limit
                    this.creditUsageModel = res.data.credit_limit[0].credit_use
                    this.creditRemainModel = res.data.credit_limit[0].remain
                    this.consigneeLists = res.data.consignee

                    this.disableCustAddress = true
                    this.addressType = '1'
                    this.customReceiverModel = res.data.result.NamaCustomer
                    this.customAddressModel = res.data.result.Alamat
                    this.customCityModel = res.data.result.Kota
                    this.customProvinceModel = res.data.result.Propinsi
                    this.lastSalesModel = res.data.last_sales['NamaSales']

                    if(res.data.customerDue.length != 0){

                        this.notYetDueModel = res.data.customerDue[0].belumJatuhTempo
                        this.overDue1Model = res.data.customerDue[0].jatuhTempLebihSatuSampai30Hari
                        this.overDue2Model = res.data.customerDue[0].jatuhTempLebih31Sampai60Hari
                        this.overDue3Model = res.data.customerDue[0].jatuhTempLebih61Sampai90Hari
                        this.overDue4Model = res.data.customerDue[0].jatuhTempLebih90Hari
                        this.totalDueModel = parseFloat(res.data.customerDue[0].jatuhTempLebihSatuSampai30Hari) + parseFloat(res.data.customerDue[0].jatuhTempLebih31Sampai60Hari) + parseFloat(res.data.customerDue[0].jatuhTempLebih61Sampai90Hari) + parseFloat(res.data.customerDue[0].jatuhTempLebih90Hari)

                        // console.log(parseInt(this.totalDueModel));

                        if(parseInt(this.totalDueModel) >= 25000){
                            this.visibleOverdueRemark = true

                            Swal.fire({
                                text: 'This customer has over due, please fill over due remark before add items',
                                icon: 'warning',
                            })

                        }

                    }

                    this.$store.dispatch('setOverlay', false)
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    // if(err.response.status == '401') {
                    //     this.$router.push('/');
                    // } else {
                    //     Swal.fire({
                    //         text: err.response.data.data,
                    //         icon: 'error',
                    //     })
                    // }
                    // console.log(err);

                })
            }
        },

        async addItems(){

            // console.log(this.dateShipModel);

            if(this.custNameModel == '' || this.custNameModel == null){

                Swal.fire({
                    text: 'Please select customer before add items',
                    icon: 'warning',
                })

                return false

            }

            if(this.shiptermModel == '' || this.shiptermModel == null){

                Swal.fire({
                    text: 'Please select ship. term before add items',
                    icon: 'warning',
                })

                return false

            }

            // if(this.totalDueModel != 0 || this.totalDueModel <= 0 && this.overdueRemarkModel == ''){

                // Swal.fire({
                //     text: 'This customer has over due, please fill over due remark',
                //     icon: 'warning',
                // })

            //     return false

            // }

            if (this.dateShipModel) {
                this.dateShipFormatted = new Date(this.dateShipModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateShipFormatted = ''
            }

            if(this.addressType == '1'){
                this.fixedReceiver = this.custNameModel
                this.fixedAddress = this.custAddressModel
                this.fixedCity = this.custCityModel
                this.fixedProvince = this.custProvinceModel
            } else if(this.addressType == '2'){
                this.fixedReceiver = this.consigneeReceiverModel
                this.fixedAddress = this.consigneeAddressModel
                this.fixedCity = this.consigneeCityModel
                this.fixedProvince = this.consigneeProvinceModel
            } else if(this.addressType == '3'){
                this.fixedReceiver = this.customReceiverModel
                this.fixedAddress = this.customAddressModel
                this.fixedCity = this.customCityModel
                this.fixedProvince = this.customProvinceModel
            }

            if(this.fixedReceiver == '' && this.fixedAddress == '' && this.fixedCity == '' && this.fixedProvince == ''){

                Swal.fire({
                    text: 'Please Complete receiver address !',
                    icon: 'warning',
                })

                return false

            }

            this.$store.dispatch('setOverlay', true)

            try {

                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/storeHeader`, { 

                        customerId: this.custIDModel ? this.custIDModel : '',
                        niknpwp: this.niknpwpModel ? this.niknpwpModel : '',
                        custType: this.custTypeModel ? this.custTypeModel : '',
                        customerName: this.custNameModel ? this.custNameModel : '',
                        customerAddress: this.custAddressModel ? this.custAddressModel : '',
                        downPayment : this.downPaymentModel ? this.downPaymentModel : '',
                        payterm: this.paytermModel ? this.paytermModel : '',
                        shipterm: this.shiptermModel ? this.shiptermModel : '',
                        xpdcName: this.xpdcNameModel ? this.xpdcNameModel : '',
                        xpdcAddress: this.xpdcAddressModel ? this.xpdcAddressModel : '',
                        xpdcPhone: this.xpdcPhoneModel ? this.xpdcPhoneModel : '',
                        fixedReceiver: this.fixedReceiver ? this.fixedReceiver : '',
                        fixedAddress: this.fixedAddress ? this.fixedAddress : '',
                        fixedCity: this.fixedCity ? this.fixedCity : '',
                        fixedProvince: this.fixedProvince ? this.fixedProvince : '',
                        shipDate: this.dateShipFormatted ? this.dateShipFormatted : '',
                        showSK: this.showSKModel ? this.showSKModel : '',
                        remark: this.remarkModel ? this.remarkModel : '',
                        specialInstructions1: this.specialInstructions1Model ? this.specialInstructions1Model : '',
                        specialInstructions2: this.specialInstructions2Model ? this.specialInstructions2Model : '',
                        specialInstructions3: this.specialInstructions3Model ? this.specialInstructions3Model : '',
                        shipInstructions1: this.shipInstructions1Model ? this.shipInstructions1Model : '',
                        shipInstructions2: this.shipInstructions2Model ? this.shipInstructions2Model : '',
                        shipInstructions3: this.shipInstructions3Model ? this.shipInstructions3Model : '',
                        overDueRemark: this.overdueRemarkModel ? this.overdueRemarkModel : '',
                        image: this.imageModel ? this.imageModel : '',
                        broker: this.brokerModel ? this.brokerModel : ''

                    },
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    }
                )
                .then(res => {

                    this.book_idModel = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.tab = 'tab-2'

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401') {
                        this.$router.push('/');
                    } else {
                        Swal.fire({
                            text: err.response.data.data,
                            icon: 'error',
                        })
                    }

                })

            } catch (e) {
                this.$store.dispatch('setOverlay', false)
                // console.log(e);
                Swal.fire({
                    text: e.message,
                    icon: 'error',
                })
            }

        },

        addSpecialInstructions()
        {
            
            $('#specialInstructionsModal').modal('show')

        },

        addOverdueRemark(){

            $('#overdueRemarkModal').modal('show')

        },

        backToTab1(){
            this.tab = 'tab-1'
        },

        async populateProdCode(){
            
            this.loadingProdCode = true
            this.prodIDModel = ''
            this.prodIDLists = []
            this.prodCodeText = 'PRODUCT CODE'

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/getProdCode?brand=${this.brandModel?this.brandModel:''}&category=${this.categoryModel?this.categoryModel:''}&machType=${this.machTypeModel?this.machTypeModel:''}&width=${this.widthModel?this.widthModel:''}&thickness=${this.thicknessModel?this.thicknessModel:''}&material=${this.materialModel?this.materialModel:''}&color=${this.colorModel?this.colorModel:''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.loadingProdCode = false
                this.prodIDModel = ''
                this.prodIDLists = res.data.result
                this.prodCodeText = 'PRODUCT CODE (found ' + res.data.result.length + ' products)'
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async brandOnChange(){

            this.$store.dispatch('setOverlay', true)

            this.categoryModel = ''
            this.categoryLists = []

            this.machTypeModel= ''
            this.machTypeLists = []

            this.thicknessModel = ''
            this.thicknessLists = []

            this.widthModel = ''
            this.widthLists = []

            this.lengthModel = ''

            this.colorModel = ''
            this.colorLists = []

            this.materialModel = ''
            this.materialLists = []

            this.addIDModel = ''
            this.torIDModel = ''
            this.quantityModel = ''

            this.prodIDModel = ''
            this.prodIDLists = []
            this.prodCodeText = 'PRODUCT CODE'

            this.priceModel = ''
            this.pripriceModeModel = 'M'
            this.remarkItemModel = ''

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/brandOnChange?brand_id=${this.brandModel?this.brandModel:''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.categoryLists = res.data.category
                this.categoryLabel = 'CATEGORY(' + res.data.category.length + ')'
                this.machTypeLists = res.data.machType
                this.machTypeLabel = 'MACH TYPE(' + res.data.machType.length + ')'
                this.thicknessLists = res.data.thickness
                this.thicknessLabel = 'THICKNESS(' + res.data.thickness.length + ')'
                this.widthLists = res.data.width
                this.widthLabel = 'WIDTH(' + res.data.width.length + ')'
                this.colorLists = res.data.color
                this.colorLabel = 'COLOR(' + res.data.color.length + ')'
                this.materialLists = res.data.material
                this.materialLabel = 'MATERIAL(' + res.data.material.length + ')'
                this.prodIDLists = res.data.prod_code
                this.prodCodeText = 'PRODUCT CODE (found ' + res.data.prod_code.length + ' products)'

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async categoryOnChange(){

            this.$store.dispatch('setOverlay', true)

            this.machTypeModel= ''
            this.machTypeLists = []

            this.thicknessModel = ''
            this.thicknessLists = []

            this.widthModel = ''
            this.widthLists = []

            this.lengthModel = ''

            this.colorModel = ''
            this.colorLists = []

            this.materialModel = ''
            this.materialLists = []

            this.addIDModel = ''
            this.torIDModel = ''
            this.quantityModel = ''

            this.prodIDModel = ''
            this.prodIDLists = []
            this.prodCodeText = 'PRODUCT CODE'

            this.priceModel = ''
            this.pripriceModeModel = 'M'
            this.remarkItemModel = ''

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/categoryOnChange?brand_id=${this.brandModel?this.brandModel:''}&category=${this.categoryModel?this.categoryModel:''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.machTypeLists = res.data.machType
                this.machTypeLabel = 'MACH TYPE(' + res.data.machType.length + ')'
                this.thicknessLists = res.data.thickness
                this.thicknessLabel = 'THICKNESS(' + res.data.thickness.length + ')'
                this.widthLists = res.data.width
                this.widthLabel = 'WIDTH(' + res.data.width.length + ')'
                this.colorLists = res.data.color
                this.colorLabel = 'COLOR(' + res.data.color.length + ')'
                this.materialLists = res.data.material
                this.materialLabel = 'MATERIAL(' + res.data.material.length + ')'
                this.prodIDLists = res.data.prod_code
                this.prodCodeText = 'PRODUCT CODE (found ' + res.data.prod_code.length + ' products)'

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async machTypeOnChange(){

            this.$store.dispatch('setOverlay', true)

            this.thicknessModel = ''
            this.thicknessLists = []

            this.widthModel = ''
            this.widthLists = []

            this.lengthModel = ''

            this.colorModel = ''
            this.colorLists = []

            this.materialModel = ''
            this.materialLists = []

            this.addIDModel = ''
            this.torIDModel = ''
            this.quantityModel = ''

            this.prodIDModel = ''
            this.prodIDLists = []
            this.prodCodeText = 'PRODUCT CODE'

            this.priceModel = ''
            this.pripriceModeModel = 'M'
            this.remarkItemModel = ''

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/machTypeOnChange?brand_id=${this.brandModel?this.brandModel:''}&category=${this.categoryModel?this.categoryModel:''}&mach_type=${this.machTypeModel?this.machTypeModel:''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.thicknessLists = res.data.thickness
                this.thicknessLabel = 'THICKNESS(' + res.data.thickness.length + ')'
                this.widthLists = res.data.width
                this.widthLabel = 'WIDTH(' + res.data.width.length + ')'
                this.colorLists = res.data.color
                this.colorLabel = 'COLOR(' + res.data.color.length + ')'
                this.materialLists = res.data.material
                this.materialLabel = 'MATERIAL(' + res.data.material.length + ')'
                this.prodIDLists = res.data.prod_code
                this.prodCodeText = 'PRODUCT CODE (found ' + res.data.prod_code.length + ' products)'

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async storeData(){

            // if(this.brandModel == '' || this.brandModel == null){

            //     Swal.fire({
            //         text: 'Please select Brand before add items',
            //         icon: 'warning',
            //     })

            //     return false

            // }

            // if(this.categoryModel == '' || this.categoryModel == null){

            //     Swal.fire({
            //         text: 'Please select Category before add items',
            //         icon: 'warning',
            //     })

            //     return false

            // }

            // if(this.machTypeModel == '' || this.machTypeModel == null){

            //     Swal.fire({
            //         text: 'Please select Category before add items',
            //         icon: 'warning',
            //     })

            //     return false

            // }

            if(this.priceModel == '' || this.priceModel == null){

                Swal.fire({
                    text: 'Please set item Price before add items',
                    icon: 'warning',
                })

                return false

            }

            // if(this.thicknessModel == '' || this.thicknessModel == null){

            //     Swal.fire({
            //         text: 'Please set Thickness before add items',
            //         icon: 'warning',
            //     })

            //     return false

            // }

            if(this.machTypeModel.trim() == 'SHT'){

                if(this.widthModel == '' || this.widthModel == null){

                    Swal.fire({
                        text: 'Please set Widthness for Sheet product before add items',
                        icon: 'warning',
                    })

                    return false

                }

            }

            if(this.lengthModel == '' || this.lengthModel == null){

                Swal.fire({
                    text: 'Please set Lenght before add items',
                    icon: 'warning',
                })

                return false

            }

            if(this.quantityModel == '' || this.quantityModel == null){

                Swal.fire({
                    text: 'Please set Quantity before add items',
                    icon: 'warning',
                })

                return false

            }

            // if(this.prodIDModel == '' || this.prodIDModel == null){

            //     Swal.fire({
            //         text: 'Please select Product Code before add items',
            //         icon: 'warning',
            //     })

            //     return false

            // }

            if(this.categoryModel.trim() != '06'){

                console.log(this.lengthModel);

                if(parseFloat(this.lengthModel) < 1.8){

                     Swal.fire({
                        text: 'This item has length under 1.8m, are you sure? Mach type : ' + this.machTypeModel + ' Length : ' + this.lengthModel,
                        allowOutsideClick: false,
                        showCancelButton: true,
                        confirmButtonText: 'OK',
                        }).then((result) => {
                        if (result.isConfirmed) {
                            this.insertingData()
                        }
                    })

                } else {
                    this.insertingData()
                }

            } else {

                this.insertingData()

            }

        },

        async insertingData(){

            this.$store.dispatch('setOverlay', true)

            if (this.dateShipModel) {
                this.dateShipFormatted = new Date(this.dateShipModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateShipFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/storeData`, { 

                    book_id : this.book_idModel ? this.book_idModel : '',
                    brand: this.brandModel ? this.brandModel : '',
                    category: this.categoryModel ? this.categoryModel : '',
                    machType: this.machTypeModel ? this.machTypeModel : '',
                    thickness: this.thicknessModel ? this.thicknessModel : '',
                    width: this.widthModel ? this.widthModel : '',
                    length: this.lengthModel ? this.lengthModel : '',
                    color: this.colorModel ? this.colorModel : '',
                    material: this.materialModel ? this.materialModel : '',
                    addID: this.addIDModel ? this.addIDModel : '',
                    torID: this.torIDModel ? this.torIDModel : '',
                    qty: this.quantityModel ? this.quantityModel : '',
                    price: this.priceModel ? this.priceModel : '',
                    prodID: this.prodIDModel ? this.prodIDModel : '',
                    priceMode: this.priceModeModel ? this.priceModeModel : '',
                    remarkItem: this.remarkItemModel ? this.remarkItemModel : '',
                    brokerFee: this.brokerFeeModel ? this.brokerFeeModel : ''

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.book_idModel = res.data.result
                this.getData(res.data.result)
                this.$store.dispatch('setOverlay', false)

                Swal.fire({
                    text: 'Item added successfully',
                    icon: 'success',
                })

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        showDeleteItem(item){
            this.order_item = item
            this.dialog_delete = true
        },

        async deleteItem(item){
            Swal.fire({
                title: 'Are u sure wants to delete this?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then((result) => {
            
                if (result.isConfirmed) {
                    this.itemLists = []

                    axios.post(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/deleteData`, { 
                            book_id : item.book_id,
                            item_num : item.item_num,
                            reason_id: this.reason_id ? this.reason_id : '',
                            remark: this.remark ? this.remark : ''
                        },
                        {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*',
                                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                            } 
                        }
                    )
                    .then(res => {
                        this.dialog_delete = false
                        this.reason_id = ''
                        this.remark = ''
                        Swal.fire('Item deleted Successfully!', '', 'success')
                        if(res.data.result.length == 0){
                            this.book_idModel = ''
                            this.getData(item.book_id)
                        }
                        this.getData(item.book_id)
                        
                    })
                    .catch(err => {

                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401') {
                            this.$router.push('/');
                        } else {
                            Swal.fire({
                                text: err.response.data.data,
                                icon: 'error',
                            })
                        }

                    })
                }

            })
            
        },

        newOrder(){

            Swal.fire({
                title: 'Are u sure wants to create new order ?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then((result) => {
            
                if (result.isConfirmed) {
                    this.itemLists = []
                    this.book_idModel = ''
                    this.$router.go(this.$router.currentRoute)
                }

            })

        },

        isNumber(event){
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 46 || keyCode > 57 )) { // 46 is dot
                event.preventDefault()
            }
        },

        isNumber2(event){
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 48 || keyCode > 57 )) { // 46 is dot
                event.preventDefault()
            }
        },

        fileChange(e) {
            let file = e.target.files[0]
            this.imageModel = ''
            if (!file.type.includes('image/')) {
                swal("Error", "Please select an image file!", "error");
                return
            }
            // if (file.size > 1500000) {
            //     swal("Failed", "File image maximum 15 MB!", "error");
            //     return
            // }
            if (typeof FileReader === 'function') {
                const reader = new FileReader()
                reader.onload = (event) => {
                    this.imageModel = event.target.result
                }
                reader.readAsDataURL(file)
            } else {
                swal("Error", "Sorry, FileReader API not supported", "error");
            }
        },

        confirmOrder(){

            this.$router.push('/admin/kmb/CustomerOrder/OrderList')

        },

        onChangeShipTerm(id){

            if(id == 'FRANCO EXPEDISI'){
                this.xpdcDetail = true
            } else {
                this.xpdcDetail = false
            }

        },

        categoryGroupOnChange(id){

            if(id){

                this.categoryLists = []

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/getCategoryByGroup?id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.categoryLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                this.categoryLists = []

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/getCategoryByGroup?id=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.categoryLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            }

        },

        onChangeAddressType(){

            if(this.addressType == '1'){
                this.consigneeShow = false
                this.customAddressShow = true
                this.disableCustAddress = true
                this.customReceiverModel = this.custNameModel
                this.customAddressModel = this.custAddressModel
                this.customCityModel = this.custCityModel
                this.customProvinceModel = this.custProvinceModel
            } else if(this.addressType == '2'){
                this.consigneeShow = true
                this.customAddressShow = false
                this.disableCustAddress = true
                this.customReceiverModel = ''
                this.customAddressModel = ''
                this.customCityModel = ''
                this.customProvinceModel = ''
            } else if(this.addressType == '3'){
                this.consigneeShow = false
                this.customAddressShow = true
                this.disableCustAddress = false
                this.customReceiverModel = ''
                this.customAddressModel = ''
                this.customCityModel = ''
                this.customProvinceModel = ''
            }

        },

        async onChangeConsignee(id){

            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/CreateOrder/getConsignee?id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    console.log(res.data)

                    this.consigneeReceiverModel = res.data.result['cons_name']
                    this.consigneeAddressModel = res.data.result['alamat']
                    this.consigneeCityModel = res.data.result['kota']
                    this.consigneeProvinceModel = res.data.result['propinsi']

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
                
            } else {

                this.consigneeReceiverModel = ''
                this.consigneeAddressModel = ''
                this.consigneeCityModel = ''
                this.consigneeProvinceModel = ''

            }

        },

        custDetail(){

            if(this.custIDModel)
            {
                this.dialogCustDetail = true

                

            }            
            
        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()}-${current.getDate()}`;
            
            const futureDate = new Date(current);
            
            futureDate.setDate(current.getDate());

            const futureDateString = `${futureDate.getFullYear()}-${futureDate.getMonth() + 1}-${futureDate.getDate()}`;

            return futureDateString;
        },

        currentDate2() {
            
            const current = new Date();
            const futureDate = new Date(current);

            futureDate.setDate(current.getDate() + 90);

            const futureDateString = `${futureDate.getFullYear()}-${futureDate.getMonth() + 1}-${futureDate.getDate()}`;

            return futureDateString;
        },

    },
    watch: {
        search (val) {
            val && val !== this.customerModel && this.getCustomer(val)
        },
    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.8);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    li {
        float: left;
    }

    table, th, td {
        border: 1px solid black;
        padding: 5px;
    }

    .line1 {
        border-top: 5px solid black;
    }

</style>